/**
 * External Dependencies
 */
import 'jquery';
var Flickity = require('flickity');
import 'selectize/dist/js/standalone/selectize';
import autoComplete from "@tarekraafat/autocomplete.js";

$(document).ready(function() {
  // SLIDER
  $('.slider').each(function() {
    var count = $('.slider > div').length;
    if ($(window).width() < 769) {
      var carousel = new Flickity('.slider', {
          pageDots: true,
          wrapAround: false,
          cellAlign: 'left',
          draggable: true,
          initialIndex: 0,
          freeScroll: false,
          imagesLoaded: true,
          prevNextButtons: true,
          adaptiveHeight: true,
          autoPlay: false,
      });
    } else {
      if (count > 3) {
        var carousel = new Flickity('.slider', {
            pageDots: true,
            wrapAround: false,
            cellAlign: 'left',
            draggable: true,
            initialIndex: 0,
            freeScroll: false,
            imagesLoaded: true,
            prevNextButtons: true,
            adaptiveHeight: true,
            autoPlay: false,
        });
      }
    }
  });

  // SCROLLED
  $(window).on('load scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  $(function () {
    var $selectize = $('select').selectize();
    $selectize.each(function() {
      if($(this).val()){
        $(this).siblings('.clearFilter').removeClass('opacity-0')
      }
    })
    $('.clearFilter').click(function(){
      var selectize = $(this).siblings('.selectized')[0].selectize;
      selectize.clear();
    })
  });

  // MODALS
  $('.close-modal').click(function() {
    $('body').removeClass('share-active');
  });

  $('.modal-bg').click(function() {
    $('body').removeClass('share-active');
  });

  $('.social-share').click(function() {
    $('body').toggleClass('share-active');
  });

  // Filter
  $('.close-modal').click(function() {
    $('body').removeClass('filter-active');
  });

  $('.modal-filter-bg').click(function() {
    $('body').removeClass('filter-active');
  });

  $('.filter').click(function() {
    $('body').toggleClass('filter-active');
  });

  // Login
  $('.close-modal').click(function() {
    $('body').removeClass('login-active');
  });

  $('.modal-login-bg').click(function() {
    $('body').removeClass('login-active');
  });

  $('.login-toggle').click(function() {
    $('body').toggleClass('login-active');
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  var ajax_url = ajaxObject.ajax_url;

  $.ajax({
    url: ajax_url,
    type: 'POST',
    data: {
      action: 'getfunctionslist',
      search: null,
    },
    error: function() {
    },
    success: function(res) {
      var results = JSON.parse(res);

      const autoCompleteJSFunction = new autoComplete({
        placeHolder: "Zoek op trefwoord",
        data: {
          src: results,
          keys: ['vacancy'],
        },
        selector: '#autoCompleteSearch',
        resultItem: {
          highlight: true,
          content: (data, element) => {
            element.style = 'display: flex; justify-content: space-between;';
            element.innerHTML = `<span class="text-sm" style='text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'>${data.match}</span>
            <span style='display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);'></span>`;
          },
        },
        events: {
          input: {
            selection: (event) => {
              const selection = event.detail.selection.value.vacancy;
              autoCompleteJSFunction.input.value = selection;
            }
          }
        }
      });
    },
  });

  // document.querySelector("#autoCompleteSearch").addEventListener("selection", function (event) {
  //   const selection = event.detail.selection.match;
  //   document.querySelector('#autoCompleteSearch').value = selection;
  // });

  $.ajax({
    url: ajax_url,
    type: 'POST',
    data: {
      action: 'getcitylist',
      search: null,
    },
    error: function() {
    },
    success: function(res) {
      var results = JSON.parse(res);

      var autoCompleteJS = new autoComplete({
        placeHolder: "Plaatsnaam",
        data: {
          src: results,
          keys: ['location'],
          filter: (list) => {
            list.sort((a,b) => {
              if (a.match < b.match) return -1;
              if (a.match > b.match) return 1;
              return 0;
            });

            return list;
          },
        },
        selector: '#autoLocationComplete',
        debounce: 300,
        resultsList: {
          maxResults: 5,
        },
        resultItem: {
          highlight: true,
          content: (data, element) => {
            element.style = 'display: flex; justify-content: space-between;';
            element.innerHTML = `<span class="text-sm" style='text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'>${data.match}</span>
            <span style='display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);'></span>`;
          },
        },
        events: {
          input: {
            selection: (event) => {
              const selection = event.detail.selection.value.city;
              autoCompleteJS.input.value = selection;
            }
          }
        }
      });
    },
  });

  // VACANCIES
  $('.vacancies-form').change(handleFilter);
  $('#search-button').click(handleFilter);

  function handleFilter (e) {
    e.preventDefault();
    var uristring = '?' + $('.vacancies-form').serialize() + '&' +$('#searchform').serialize();

    window.location.href = uristring;
  }
});
